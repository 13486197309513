import Laddu from "@public/assets/icons/laddu_home.svg";
import Star from "@public/assets/icons/star.png";

import Img11 from "../../../public/assets/home/11img.webp";
import Img12 from "../../../public/assets/home/12img.webp";
import Img14 from "../../../public/assets/home/14img.webp";
import Img15 from "../../../public/assets/home/15img.webp";
import Img16 from "../../../public/assets/home/16img.webp";
import Img1 from "../../../public/assets/home/1img.webp";
import Img2 from "../../../public/assets/home/5img.webp";
import Img6 from "../../../public/assets/home/6img.webp";
import Img7 from "../../../public/assets/home/7img.webp";
import Img8 from "../../../public/assets/home/8img.webp";
import Img9 from "../../../public/assets/home/9img.webp";

import { Typography, useMediaQuery } from "@mui/material";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import styles from "../styles/topSection.module.css";
import theme from "src/globalModule/styles/theme";

const TopSection = () => {
  const router = useRouter();
  const smallScreen = useMediaQuery("(max-width: 600px)");
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.topRoot}>
        <div className={styles.leftSide}>
          <div className={styles.totalCustomer}>
            <div className={styles.sec1_text}>
              {t("Celebrating 1L+ Happy Devotees 🙏🏻")}
            </div>
          </div>

          <Typography className={styles.templeHead}>
            {t("Experience Hindu Pujas & Rituals with Utsav 🛕🕉️")}
          </Typography>

          <Typography className={styles.aboutUtsav}>
            {t(
              "Offer pujas, watch darshan & receive holy prasad at your home from most auspicious temples across India."
            )}
          </Typography>

          <div className={styles.featureDiv}>
            <div className={styles.paymentFeature}>
              <Image src={Star} height={30} width={30} alt="" />
              <Typography
                fontSize={"15px"}
                sx={{
                  fontWeight: 600,
                  color: theme.palette.text.secondaryDark,
                }}
              >
                {t("4.6 ⭐ Puja Rating")}
              </Typography>
            </div>

            <div className={styles.paymentFeature}>
              <Laddu />
              <Typography
                fontSize={"15px"}
                sx={{
                  fontWeight: 600,
                  color: theme.palette.text.secondaryDark,
                }}
              >
                {smallScreen
                  ? t("Authentic Prasad")
                  : t("Authentic Temple Prasad")}
              </Typography>
            </div>
          </div>

          <div className={styles.bannerBtn}>
            <button
              className={styles.bookBtn}
              onClick={() => {
                router.push("/puja");
              }}
            >
              {t("Book Puja")}
            </button>

            {/* <button
              className={styles.demoBtn}
              onClick={() => {
                if (demoVideoRef.current) {
                  const pageYOffset =
                    window.innerHeight / 2 -
                    demoVideoRef.current.offsetHeight / 2;
                  demoVideoRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                    offset: pageYOffset,
                  });
                }
              }}
            >
              {t("Watch Demo")}
            </button> */}
          </div>
        </div>

        {smallScreen ? (
          <div className={styles.rightSideContainerMobile}>
            <div className={styles.leftGradient}></div>

            <div className={styles.rightGradient}></div>

            <div className={styles.mobileItems}>
              <div className={styles.item}>
                <Image
                  src={Img2}
                  alt={t("home-image")}
                  // height={`500px`}
                  className={styles.bannerLeftCardImg1}
                  width={300}
                  height={180}
                  placeholder="blur"
                  blurDataURL={Img2}
                  loading="eager"
                  priority
                />
              </div>

              <div className={styles.item}>
                <Image
                  src={Img11}
                  alt={t("temple-image")}
                  className={styles.bannerLeftCardImg1}
                  width={300}
                  height={180}
                  placeholder="blur"
                  blurDataURL={Img11}
                  loading="eager"
                  priority
                />
              </div>

              <div className={styles.item}>
                <Image
                  src={Img9}
                  alt={t("temple-image")}
                  className={styles.bannerLeftCardImg1}
                  width={300}
                  height={180}
                  placeholder="blur"
                  blurDataURL={Img9}
                  loading="eager"
                  priority
                />
              </div>

              <div className={styles.item}>
                <Image
                  src={Img12}
                  alt={t("temple-image")}
                  className={styles.bannerLeftCardImg1}
                  width={300}
                  height={180}
                  placeholder="blur"
                  blurDataURL={Img12}
                  loading="eager"
                  priority
                />
              </div>

              <div className={styles.item}>
                <Image
                  src={Img14}
                  alt={t("temple-image")}
                  className={styles.bannerLeftCardImg1}
                  width={300}
                  height={180}
                  placeholder="blur"
                  blurDataURL={Img14}
                  loading="eager"
                  priority
                />
              </div>

              <div className={styles.item}>
                <Image
                  src={Img6}
                  alt={t("temple-image")}
                  className={styles.bannerLeftCardImg1}
                  width={300}
                  height={180}
                  placeholder="blur"
                  blurDataURL={Img6}
                  loading="eager"
                  priority
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.rightSideContainer}>
            <div className={styles.topGradient}></div>

            <div className={styles.rightSide}>
              <div className={styles.bannerLeftCard}>
                <Image
                  src={Img1}
                  alt={t("home-image")}
                  height={`500px`}
                  className={styles.bannerLeftCardImg}
                  placeholder="blur"
                  blurDataURL={Img1}
                  loading="eager"
                  priority
                />
                <Image
                  src={Img2}
                  alt={t("temple-image")}
                  className={styles.bannerLeftCardImg}
                  placeholder="blur"
                  blurDataURL={Img2}
                  loading="eager"
                  priority
                />
                <Image
                  src={Img9}
                  alt={t("temple-image")}
                  className={styles.bannerLeftCardImg}
                  placeholder="blur"
                  blurDataURL={Img9}
                  loading="eager"
                  priority
                />
                <Image
                  src={Img12}
                  alt={t("temple-image")}
                  className={styles.bannerLeftCardImg}
                  placeholder="blur"
                  blurDataURL={Img12}
                  loading="eager"
                  priority
                />
                <Image
                  src={Img14}
                  alt={t("temple-image")}
                  className={styles.bannerLeftCardImg}
                  placeholder="blur"
                  blurDataURL={Img14}
                  loading="eager"
                  priority
                />
                <Image
                  src={Img6}
                  alt={t("temple-image")}
                  className={styles.bannerLeftCardImg}
                  placeholder="blur"
                  blurDataURL={Img6}
                  loading="eager"
                  priority
                />
              </div>

              <div className={styles.bannerRightCard}>
                <Image
                  src={Img7}
                  alt={t("temple-image")}
                  className={styles.bannerLeftCardImg}
                  placeholder="blur"
                  blurDataURL={Img7}
                  loading="eager"
                  priority
                />

                <Image
                  src={Img8}
                  alt={t("temple-image")}
                  className={styles.bannerLeftCardImg}
                  placeholder="blur"
                  blurDataURL={Img8}
                  loading="eager"
                  priority
                />

                <Image
                  src={Img15}
                  alt={t("temple-image")}
                  className={styles.bannerLeftCardImg}
                  placeholder="blur"
                  blurDataURL={Img15}
                  loading="eager"
                  priority
                />

                <Image
                  src={Img16}
                  alt={t("temple-image")}
                  className={styles.bannerLeftCardImg}
                  placeholder="blur"
                  blurDataURL={Img16}
                  loading="eager"
                  priority
                />
              </div>
            </div>

            <div className={styles.bottomGradient}></div>
          </div>
        )}
      </div>
    </>
  );
};

export default TopSection;
